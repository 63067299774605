/**
 * Very basic state with getter and setter (no vuex), which can be used by frontend and backend.
 * This is needed by the filters because they have no access to the locale otherwise.
 */
let locale = 'de-DE';

export function getLocale(): string {
	return locale;
}

export function setLocale(_locale: string): void {
	locale = _locale;
}
