function trackLinotypeFonts(): void {
	(window as any).MTUserId = '0c1a8c29-30f9-49e7-914e-c8602aaf992c';

	const MTFontIds: string[] = [];
	MTFontIds.push('1564509'); // VAG Rounded™ W04 Thin
	// MTFontIds.push('1564515'); // VAG Rounded™ W04 Light
	// MTFontIds.push('1564521'); // VAG Rounded™ W04 Bold
	MTFontIds.push('1564527'); // VAG Rounded™ W04 Black

	(window as any).MTFontIds = MTFontIds;

	// @ts-ignore
	import('@libraries/linotype/mtiFontTrackingCode.js');
}

export default function loadFontTracking(): void {
	// Track font usage with a delay of 1s, so the import scripts can load first
	window.setTimeout((): void => trackLinotypeFonts(), 1000);
}
