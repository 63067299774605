export enum AnchorNavigationEvents {
	change = 'AnchorNavigationChange'
}

export default class AnchorNavigationUtil {
	public static goToAnchor(target: string): void {
		const event = new CustomEvent(AnchorNavigationEvents.change, { detail: { target } });
		const targetElement = document.querySelector(target) as HTMLElement;

		if (targetElement) {
			/**
			 *  Dispatch event so other components can react to the anchor change
			 *  e.g. open accordion item if it contains the anchor, or switch
			 *  to the correct tab if it contains the anchor
			 */
			document.dispatchEvent(event);

			/**
			 * If the anchor is a modal or inside a modal, we don't want to scroll
			 * to the anchor, because the modal will be opened from the event listener
			 * of the specific component (e.g. ShopList.client.ts)
			 */
			if (!AnchorNavigationUtil.isTargetModalRelated(target)) {
				AnchorNavigationUtil.scrollToElement(targetElement as HTMLElement);
			}
		}
	}

	public static isTargetModalRelated(target: string): boolean {
		const targetElement = document.querySelector(target) as HTMLElement;
		const isTargetModal = targetElement.classList.contains('c-modal');
		const isTargetInsideModal = !!targetElement.closest('.c-modal__pane');

		return isTargetModal || isTargetInsideModal;
	}

	/**
	 * Scroll to given anchor. If the anchor is inside a parent element which
	 * should be aligned with the viewport, add the data-deeplink-target.
	 */
	private static scrollToElement(targetElement: HTMLElement): void {
		if (targetElement) {
			const parentElementSelector = targetElement.dataset.deeplinkTarget;
			const parentElement = parentElementSelector ? targetElement.closest(parentElementSelector) : null;
			const scrollTarget = parentElement || targetElement;

			scrollTarget.scrollIntoView({ behavior: 'smooth' });
		}
	}

	/**
	 * On page load wait until all modules have the data-initialized attribute
	 * set to true before navigating to the given anchor (hash) in the url.
	 * This is because we need to wait for all modules to be initialized before
	 * we know the exact height of the page.
	 */
	public static initPageLoad(): void {
		const pageContainsModuleWithHashNavigation = !!document.querySelector('.has-hash-navigation');

		if (pageContainsModuleWithHashNavigation) {
			return;
		}

		const anchorElement = ((): HTMLElement | null => {
			try {
				return document.querySelector(window.location.hash);
			} catch {
				return null;
			}
		})();

		if (anchorElement) {
			window.addEventListener('load', (): void => {
				const modulesToInitialize = document.querySelectorAll('[data-init]');
				const limit = 3000;
				const timer = 200;
				let counter = 0;

				const interval = setInterval((): void => {
					const initializedModules = document.querySelectorAll('[data-initialized="true"]');

					if (initializedModules.length >= modulesToInitialize.length || counter >= limit) {
						clearInterval(interval);
						AnchorNavigationUtil.goToAnchor(window.location.hash);
						window.history.replaceState(null, '', `${window.location.pathname}${window.location.search}`);
					}

					counter += timer;
				}, timer);
			});
		}

		window.addEventListener(
			'hashchange',
			(): void => {
				AnchorNavigationUtil.goToAnchor(window.location.hash);
			},
			false
		);
	}
}
