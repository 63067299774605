import Axios, { AxiosInstance, AxiosResponse } from 'axios';
import { captureAndLogError } from '@libraries/sentry/browser';
import GeoUtil from '@frontend/utils/GeoUtil';

export default async function loadCountryAnnouncementLayer(): Promise<void> {
	if (!(window as any).HARIBO.global.contentProperties.countryAnnouncementLayers) {
		return;
	}

	const client: AxiosInstance = Axios.create();

	try {
		// Load country code of current user (non-cached endpoint)
		const countryCode: string = await GeoUtil.getVisitorCountryCode();

		// Does a layer exist for the given country code?
		if (!Object.keys((window as any).HARIBO.global.contentProperties.countryAnnouncementLayers).includes(countryCode)) {
			return;
		}

		const layerUrlForCountryCode: string = (window as any).HARIBO.global.contentProperties.countryAnnouncementLayers[
			countryCode
		];

		// Load layer
		let response: AxiosResponse;
		try {
			response = await client.get(layerUrlForCountryCode);
		} catch (error) {
			// Probably 404 because country layer is not valid anymore
			console.error('Could not load country layer.');
			if (error.response.status !== 404) {
				captureAndLogError(error);
			}
			return;
		}

		// Get content in <main class="l-main"></main>
		const mainContentMatches: string[] = response.data.match(/<main class="l-main">((.|[\n\r])*)<\/main>/im);

		if (!mainContentMatches) {
			return;
		}

		// Add to DOM
		const div: HTMLElement = document.createElement('div');
		div.innerHTML = mainContentMatches[1];
		document.body.appendChild(div);

		// Trigger hydration again
		document.dispatchEvent(new CustomEvent('DOMContentLoaded'));
	} catch (error) {
		console.error(error);
	}
}
