import Axios, { AxiosInstance, AxiosResponse } from 'axios';

const geoUrl = 'https://www.haribo.com/geo/country';

export default class GeoUtil {
	public static async getVisitorCountryCode(): Promise<string> {
		const dynamicWindow = (window as any) as { loadVisitorCountryCodePromise: Promise<string> | undefined };

		if (!dynamicWindow.loadVisitorCountryCodePromise) {
			dynamicWindow.loadVisitorCountryCodePromise = new Promise(
				async (resolve, reject): Promise<void> => {
					const client: AxiosInstance = Axios.create();

					try {
						// Load country code of current user (non-cached endpoint)
						const countryCodeResponse: AxiosResponse = await client.get(geoUrl);

						const countryCode: string = countryCodeResponse.data.countryCode.toLowerCase();

						resolve(countryCode);
					} catch (error) {
						reject(error);
					}
				}
			);
		}

		return dynamicWindow.loadVisitorCountryCodePromise;
	}

	public static async getGeoResponseHeaderDate(): Promise<string | undefined> {
		const client: AxiosInstance = Axios.create();

		try {
			const response = await client.get(geoUrl);
			return response.headers.date;
		} catch (error) {
			console.error(error);
		}
	}
}
