import { TinyColor } from '@ctrl/tinycolor';

let styleElement: HTMLElement;
const backgroundColors: string[] = [];
const textColors: string[] = [];

export function getBackgroundColorCss(hexColor: string): string {
	const hueColor: TinyColor = new TinyColor(hexColor).spin(-10);

	return `
body .u-color-bg-${hexColor} {
	--background-color: #${hexColor};
	--gradient-color: #${new TinyColor(hexColor).lighten(15).toHex()};
	--pieces-hue-color: #${hueColor.toHex()};
	--pieces-hue-color-transparent: ${hueColor.setAlpha(0.0).toRgbString()};
	background-color: #${hexColor};
}`;
}

export function getTextColorCss(hexColor: string): string {
	return `
body .u-color-text-${hexColor} {
	color: #${hexColor};
}`;
}

function appendCss(content: string): void {
	if (!styleElement) {
		styleElement = document.createElement('style');
		document.body.appendChild(styleElement);
	}
	styleElement.innerHTML += content;
}

export function createBackgroundColorStylesFor(hexColors: string[]): void {
	let css = '';

	hexColors.forEach((hexColor: string): void => {
		if (backgroundColors.includes(hexColor)) {
			// Ignore already handled colors
			return;
		}

		backgroundColors.push(hexColor);
		css += getBackgroundColorCss(hexColor);
	});

	appendCss(css);
}

export function creatTextColorStylesFor(hexColors: string[]): void {
	let css = '';

	hexColors.forEach((hexColor: string): void => {
		if (textColors.includes(hexColor)) {
			// Ignore already handled colors
			return;
		}

		textColors.push(hexColor);
		css += getTextColorCss(hexColor);
	});

	appendCss(css);
}

/**
 * Parse document and add all found "u-color-" classes as color style.
 */
export function createColorStylesForDocument(): void {
	let hexColors: string[] = [];
	Array.from(document.body.querySelectorAll('[class*="u-color-bg-"]')).forEach((element: Element): void => {
		const matches = element.className.match(/u-color-bg-(\S{6})/i);
		if (matches && matches.length) {
			const hexColor = (matches as string[])[1].toLowerCase();
			hexColors.push(hexColor);
		}
	});
	createBackgroundColorStylesFor(hexColors);

	//

	hexColors = [];
	Array.from(document.body.querySelectorAll('[class*="u-color-text-"]')).forEach((element: Element): void => {
		const matches = element.className.match(/u-color-text-(\S{6})/i);
		const hexColor = (matches as string[])[1].toLowerCase();

		hexColors.push(hexColor);
	});
	creatTextColorStylesFor(hexColors);
}
