export enum UserConsentEvent {
	ConsentUpdate = 'UserConsentUpdate'
}

export default class UserConsentUtil {
	public static init(): void {
		window.addEventListener('CookiebotOnLoad', (): void => {
			window.dispatchEvent(new CustomEvent(UserConsentEvent.ConsentUpdate));
		});

		window.addEventListener('CookiebotOnAccept', (): void => {
			window.dispatchEvent(new CustomEvent(UserConsentEvent.ConsentUpdate));
		});

		window.addEventListener('CookiebotOnDecline', (): void => {
			window.dispatchEvent(new CustomEvent(UserConsentEvent.ConsentUpdate));
		});
	}

	public static get necessaryConsent(): boolean {
		const Cookiebot: any = UserConsentUtil.Cookiebot;

		return Cookiebot && Cookiebot.consent && Cookiebot.consent.necessary;
	}

	public static get preferencesConsent(): boolean {
		const Cookiebot: any = UserConsentUtil.Cookiebot;

		return Cookiebot && Cookiebot.consent && Cookiebot.consent.preferences;
	}

	public static get statisticsConsent(): boolean {
		const Cookiebot: any = UserConsentUtil.Cookiebot;

		return Cookiebot && Cookiebot.consent && Cookiebot.consent.statistics;
	}

	public static get marketingConsent(): boolean {
		const Cookiebot: any = UserConsentUtil.Cookiebot;

		return Cookiebot && Cookiebot.consent && Cookiebot.consent.marketing;
	}

	public static requestConsent(): void {
		if (!UserConsentUtil.Cookiebot) return;

		UserConsentUtil.Cookiebot.renew();
	}

	private static get Cookiebot(): any | null {
		const Cookiebot: any = (window as any).Cookiebot;

		if (!Cookiebot) {
			return null;
		}

		if (!Cookiebot.consent || !Cookiebot.renew) {
			// HTML Element with id="Cookiebot" found, but not the Cookiebot script
			return null;
		}

		return Cookiebot;
	}
}
