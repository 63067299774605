import { VueConstructor } from 'vue';
import filters from '../filters/global';
import GlobalsMixin from '../mixins/GlobalsMixin';

export const install = (Vue: VueConstructor): void => {
	Object.entries(filters).forEach(([filterName, filterFunction]): void => {
		Vue.filter(filterName, filterFunction);
	});

	Vue.mixin(new GlobalsMixin());
};

export default {
	install
};
