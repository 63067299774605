import stringFormatObj from 'string-format-obj';
import { getLocale } from '@frontend/store/locale';
import IFile from '@frontend/types/base/IFile';

const formatI18n = (tokenString: string, tokens: Record<string, any>): string => {
	if (!tokenString) return '';
	return stringFormatObj(tokenString, tokens);
};

export default {
	formatI18n,

	formatPrice(price: number): string {
		if (!price) {
			return '–';
		}

		return price.toLocaleString(getLocale(), { minimumFractionDigits: 2 }).replace(/00$/, '–');
	},

	formatPriceWithCurrency(price: number): string {
		if (!price) {
			return '–';
		}

		return `CHF ${price.toLocaleString(getLocale(), { minimumFractionDigits: 2 }).replace(/00$/, '–')}`;
	},

	formatDate(_date: number | string | Date): string {
		const date: Date = typeof _date === 'object' ? _date : new Date(_date);

		return date.toLocaleDateString(getLocale(), {
			month: '2-digit',
			day: '2-digit',
			year: 'numeric'
		});
	},

	formatDateTime(_date: number | string | Date): string {
		const date: Date = typeof _date === 'object' ? _date : new Date(_date);

		return `${date.toLocaleDateString(getLocale())} ${date.toLocaleTimeString(getLocale(), {
			hour: 'numeric',
			minute: '2-digit'
		})}`;
	},

	formatIsoDate(_date: number | string | Date): string {
		const date: Date = typeof _date === 'object' ? _date : new Date(_date);

		return date.toISOString();
	},

	nl2br(text: string): string {
		if (!text) {
			return text;
		}

		return text.replace(/\n/g, '<br>');
	},

	fileSize(fileObj: IFile): string {
		if (!fileObj.size) {
			return '';
		}

		const units: string[] = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
		const i: number = Math.floor(Math.log(fileObj.size) / Math.log(1024));
		if (i === 0) {
			return `${fileObj.size} ${units[i]}`;
		}

		return `${(fileObj.size / 1024 ** i).toLocaleString(getLocale(), {
			minimumFractionDigits: 1,
			maximumFractionDigits: 1
		})} ${units[i]}`;
	}
};
