import 'what-input/dist/what-input';
import { VueConstructor } from 'vue';
// @ts-ignore
import { VTooltip } from 'v-tooltip';
import { captureAndLogError, initSentry } from '@/libraries/sentry/browser';
import AnchorNavigationUtil from '@frontend/utils/AnchorNavigationUtil';
import loadFontTracking from './functions/loadFontTracking';
import loadCountryAnnouncementLayer from './functions/loadCountryAnnouncementLayer';
import { createColorStylesForDocument } from './functions/colorStyles';
import GlobalsPlugin from './plugins/GlobalsPlugin';
import '@assets/css/main.scss';
// @ts-ignore
import initHydration from '!babel-loader!@swey/vue-hydration/hydration-webpack-loader?{"files":"./frontend/src/components/{01-components,02-modules}/**/*.vue"}!@swey/vue-hydration/hydration'; //eslint-disable-line
import UserConsentUtil from './utils/UserConsentUtil';
import { setLocale } from './store/locale';

initSentry(process.env.SENTRY_RELEASE || undefined);
UserConsentUtil.init();

async function executeApp(): Promise<void> {
	console.log(`[main] Version ${process.env.VERSION}`);
	setLocale((window as any).HARIBO.global.locale);
	loadFontTracking();
	createColorStylesForDocument();
	initHydration((window as any).HARIBO, (Vue: VueConstructor): void => {
		Vue.use(GlobalsPlugin);
		Vue.directive('tooltip', VTooltip);
	});
	loadCountryAnnouncementLayer();
	createColorStylesForDocument();
	AnchorNavigationUtil.initPageLoad();
}

executeApp().catch((error: Error): void => {
	try {
		captureAndLogError(error);
	} catch (errorDuringCapture) {
		console.error('Error during exception capture in Sentry', error);
	}
});

if ((window as any).HARIBO.global.env.DEV_HELPERS_ENABLED) {
	import('./dev');
}
