/**
 * Sentry Connector for Browser based environments (non-Node)
 */
import * as Sentry from '@sentry/browser';

// emit events only if sentry is enabled for the current environment:
const beforeSend = (event: Sentry.Event): Sentry.Event | null => {
	// Catch ChunkLoadErrors so they will not be handled by Sentry
	if (
		event.exception &&
		event.exception.values &&
		event.exception.values.length > 0 &&
		event.exception.values[0].type === 'ChunkLoadError'
	) {
		return null;
	}

	return process.env.SENTRY_ENABLED ? event : null;
};

export function initSentry(release?: string): void {
	Sentry.init({
		dsn: process.env.SENTRY_DSN,
		environment: (window as any).HARIBO.global.env.STAGE,
		release,
		beforeSend
	});
}
export function captureAndLogError(error: Error): void {
	console.error(error);
	Sentry.captureException(error);
}

export default Sentry;
