import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import IGlobalAppSettings from '../types/base/IGlobalAppSettings';
import I18n from '../types/base/I18n';

let uid = 0;

export default class GlobalsMixin extends Vue {
	public props: Record<string, any> = {
		global: {
			type: Object,
			default(): IGlobalAppSettings {
				let global: Partial<IGlobalAppSettings> = {
					locale: 'de-DE'
				};

				if (this && this.$parent && this.$parent.global) global = { ...global, ...this.$parent.global };

				return global as IGlobalAppSettings;
			}
		},
		i18n: {
			type: Object,
			default(): I18n {
				let i18n: I18n = {};
				if (this && this.$parent && this.$parent.i18n) i18n = { ...i18n, ...this.$parent.i18n };

				return i18n;
			}
		},
		uid: {
			type: String,
			default(): string {
				return this && this.id ? this.id : (++uid).toString();
			}
		}
	};

	public computed: Record<string, any> = {
		primaryBackgroundColorStyle(): string | null {
			if (this.styleVariant && this.styleVariant === 'maoam') return `u-color-bg-maoam`;
			if (this.primaryBackgroundColor) return `u-color-bg-${this.primaryBackgroundColor}`;
			return null;
		},

		primaryTextColorStyle(): string | null {
			if (this.primaryTextColor) return `u-color-text-${this.primaryTextColor}`;
			return null;
		}
	};

	public methods: Record<string, any> = {
		imageMask(value: string): string | null {
			if (value) return `u-svg-mask-${value}`;
			return null;
		}
	};

	@Prop(Object)
	public readonly i18n!: Record<string, string>;

	@Prop(Object)
	public readonly global!: IGlobalAppSettings;

	public componentId?: string;

	public created(): void {
		if (!this.$vnode) {
			return;
		}

		if (!this.$vnode.data) {
			this.$vnode.data = {};
		}

		if (!this.$vnode.data.attrs) {
			this.$vnode.data.attrs = {};
		}

		if (!this.$vnode.data.attrs.id && this.$options.propsData && (this.$options.propsData as any).id) {
			this.$vnode.data.attrs.id = (this.$options.propsData as any).id;
		}

		if (this.$options.componentId) {
			this.$vnode.data.attrs['data-component-id'] = this.$options.componentId;
		}
	}
}
